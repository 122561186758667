/** @jsx jsx */
//import React, { useState } from 'react';
import React from 'react';
import {Container, Input, Label, Button, Flex, jsx, Alert, Box, Heading} from 'theme-ui';
import SEO from '../components/seo';
import Layout from '../components/layout/layout';
import Amplify from '@aws-amplify/core'
import { Auth, API } from 'aws-amplify'
import awsconfig from '../../aws-exports';
import PrimaryLayout from '../../components/layout/primary/primary';

Amplify.configure(awsconfig);

const Activate = () => {
	const [pass, setPass] = React.useState();
	const [repeatPass, setRepeatPass] = React.useState();
	const [updating, setUpdating] = React.useState(false);
	const [error, setError] = React.useState(null);
	const handleSubmit = async () => {
		setUpdating(true);
		if(pass !== repeatPass) {
			setError("input password and confirm value do not match")
			return;
		}
		const di = getParameterByName('di');
		const kt = getParameterByName('kt');
		const em = getParameterByName('em');
		const activationUrl = "https://indusfoods-us.myshopify.com/account/reset/" + di + "/" + kt;
		
		if (!error) {
			const apiName = 'indusapi'; // replace this with your api name.
			const path = '/activate'; //replace this with the path you have configured on your API
			const myInit = {
				body: {
				  activateUrl: activationUrl,
				  pass: pass,
				  email: em
				}, // replace this with attributes you need
				headers: {}, // OPTIONAL
			};

			const resp = await new Promise ( (resolve) => { 
				API
				  .post(apiName, path, myInit)
				  .then(response => {
					// Add your code here
					resolve(response)
				  })
				  .catch(error => {
					console.log(error.response);
					resolve(error.response.data)
				  })
			  });	
			  if (resp.error) {
				setError(resp.error.message)
			  }
			  else {
				setError(resp.success)
			  }
		}		
		//const activationUrl = window.location.href.replace("shop.indusfoods.us", "indusfoods-us.myshopify.com")
	}
	
	const getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}	
	
	return (
	<PrimaryLayout>
	  <Container py="40px">
	  <Box sx={{marginX: 'auto', width: '100%', maxWidth: 750, py: [10, 15, 30]}}>
	    <Input
		  placeholder='New password(min 8 chars)'
		  onChange={e => {setPass(e.target.value)}}
		  type="password"
		/>
	  </Box>	
	  <Box sx={{marginX: 'auto', width: '100%', maxWidth: 750, py: [10, 15, 30]}}>
	    <Input
		  placeholder='Confirm password'
		  onChange={e => {setRepeatPass(e.target.value)}}
		  type="password"
		/>	
	  </Box>	
		{error ? (
		<Box sx={{marginX: 'auto', width: '100%', maxWidth: 750, py: [10, 15, 30]}}>
			<Alert>{error}</Alert>
		</Box>
		) : 
		<Button onClick={handleSubmit} sx={{marginX: 'auto', width: '100%', maxWidth: 750, py: [10, 15, 30]}}>{updating ? 'Activating ...' : 'Submit'}</Button> 
		}	
	  </Container>
	</PrimaryLayout>
	)
};

export default Activate;
